<template>
  <div class="resolution">
    <MobileTable
      v-model:selection="store.state.assembly.selectedResolutions"
      :label="$route.name"
      :columns="resolution.columns"
      :data="store.state.assembly.resolutions"
    />
    <DesktopTable
      v-model:selection="store.state.assembly.selectedResolutions"
      :label="$route.name"
      :columns="resolution.columns"
      :data="store.state.assembly.resolutions"
    />
    <Actions :items="resolution.actions" />
    <BasicModal v-model:visible="resolution.showDialog">
      <template v-slot:dialog>
        <BasicTitle title="Datos de la Resolución" />
        <BasicSubtitle
          subtitle="Los campos señalados con (*) son obligatorios."
        />
        <div class="container-form">
          <BasicLabel label="Título *" />
          <BorderInput
            v-model="resolution.data.title"
            label="Título *"
            @keyup.enter="onSave"
          />
          <FormError :show="resolution.rules.title" />
          <BasicLabel label="Cuerpo del mensaje *" />
          <OutlinedTextArea v-model="resolution.data.text" />
          <FormError :show="resolution.rules.text" message="Ingrese un texto" />
          <PrimaryButton label="Guardar" :click="onSave" />
        </div>
      </template>
    </BasicModal>
  </div>
</template>

<script>
import { useRouter, useRoute } from "vue-router";
import Actions from "../../components/shared/Actions.vue";
import MobileTable from "../../widgets/tables/MobileTable";
import DesktopTable from "../../widgets/tables/DesktopTable";
import BasicTitle from "../../widgets/title/BasicTitle";
import BasicSubtitle from "../../widgets/subtitle/BasicSubtitle";
import BasicLabel from "../../widgets/label/BasicLabel";
import BorderInput from "../../widgets/input/BorderInput";
import FormError from "../../widgets/error/FormError";
import BasicModal from "../../widgets/modal/BasicModal.vue";
import PrimaryButton from "../../widgets/button/PrimaryButton";
import OutlinedTextArea from "../../widgets/textarea/OutlinedTextArea.vue";
import { onBeforeMount, onBeforeUnmount, reactive, watchEffect } from "vue";
import store from "../../store";
import { openMode, actions } from "../../constants";

export default {
  components: {
    MobileTable,
    DesktopTable,
    Actions,
    BasicTitle,
    BasicSubtitle,
    BasicLabel,
    BorderInput,
    FormError,
    PrimaryButton,
    BasicModal,
    OutlinedTextArea,
  },

  setup() {
    const router = useRouter();
    const route = useRoute();
    const onCreate = () => {
      store.commit("setOpenMode", openMode.CREATE);
      resolution.showDialog = true;
    };
    const onModify = () => {
      if (store.state.assembly.selectedResolutions.length !== 1) {
        store.state.toast.add({
          severity: "info",
          summary: "",
          detail: "Debe seleccionar un registro!",
          life: 5000,
        });
      } else {
        store.commit("setOpenMode", openMode.MODIFY);
        const selected = store.state.assembly.selectedResolutions[0];
        resolution.data.id = selected.id;
        resolution.data.assemblyId = selected.assemblyId;
        resolution.data.title = selected.title;
        resolution.data.text = selected.text;
        resolution.showDialog = true;
      }
    };
    const onRemove = async () => {
      if (store.state.assembly.selectedResolutions.length !== 1) {
        store.state.toast.add({
          severity: "info",
          summary: "",
          detail: "Debe seleccionar un registro!",
          life: 5000,
        });
      } else {
        store.commit("setLoading", true);
        const response = await store.dispatch(
          actions.assemblyActions.deleteResolution,
          store.state.assembly.selectedResolutions[0].id
        );
        if (response.ok) {
          store.commit("setSelectedResolutions", []);
          await store.dispatch(actions.assemblyActions.findAllResolution, {
            assemblyId: store.state.assembly.selectedAssemblies[0].id,
          });
          store.commit("setLoading", false);
        } else {
          store.state.toast.add({
            severity: "error",
            summary: "",
            detail: response.message,
            life: 5000,
          });
          store.commit("setLoading", false);
        }
      }
    };

    const resolution = reactive({
      showDialog: false,
      data: {
        id: "",
        assemblyId: store.state.assembly.selectedAssemblies[0].id,
        title: "",
        text: "",
      },
      rules: {
        title: false,
        text: false,
      },
      columns: [
        {
          field: "title",
          header: "Resolución",
          sort: true,
        },
        {
          field: "text",
          header: "Descripción",
        },
      ],
      actions: [
        {
          name: "Nuevo",
          action: onCreate,
          hidden: router
            .getRoutes()
            .find((route) => route.path === "/asambleas/resoluciones/asociar"),
        },
        {
          name: "Modificar",
          action: onModify,
          hidden: router
            .getRoutes()
            .find((route) => route.path === "/asambleas/resoluciones/asociar"),
        },
        {
          name: "Eliminar",
          action: onRemove,
          hidden: router
            .getRoutes()
            .find((route) => route.path === "/asambleas/resoluciones/asociar"),
        },
      ],
    });

    const validate = () => {
      let valid = true;

      if (!resolution.data.title) {
        resolution.rules.title = true;
        valid = false;
      }
      if (!resolution.data.text) {
        resolution.rules.text = true;
        valid = false;
      }

      return valid;
    };

    const onSave = async () => {
      if (!validate()) return;

      store.commit("setLoading", true);

      const request = {
        id: resolution.data.id,
        assemblyId: resolution.data.assemblyId,
        title: resolution.data.title,
        text: resolution.data.text,
      };

      let response = null;
      if (store.state.openMode === openMode.MODIFY) {
        response = await store.dispatch(
          actions.assemblyActions.updateResolution,
          request
        );
      } else {
        response = await store.dispatch(
          actions.assemblyActions.createResolution,
          request
        );
      }
      if (response.ok) {
        store.commit("setSelectedResolutions", []);
        resolution.showDialog = false;
        resolution.data.title = "";
        resolution.data.text = "";
        await store.dispatch(actions.assemblyActions.findAllResolution, {
          assemblyId: store.state.assembly.selectedAssemblies[0].id,
        });
        store.commit("setLoading", false);
      } else {
        store.commit("setLoading", false);
        store.state.toast.add({
          severity: "error",
          summary: "",
          detail: response.message,
          life: 5000,
        });
      }
    };

    onBeforeMount(async () => {
      store.commit("setLoading", true);
      store.commit("setSelectedResolutions", []);
      store.commit("addBreadcrumb", { label: route.name, to: route.path });
      await store.dispatch(actions.assemblyActions.findAllResolution, {
        assemblyId: store.state.assembly.selectedAssemblies[0].id,
      });
      store.commit("setLoading", false);
    });

    onBeforeUnmount(() => {
      store.commit("removeBreadcrumb");
    });

    watchEffect(() => {
      if (resolution.data.title) resolution.rules.title = false;
      if (resolution.data.text) resolution.rules.text = false;
    });

    return { store, resolution, onSave };
  },
};
</script>

<style scoped>
.container-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0;
}
</style>
